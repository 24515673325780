<template>
    <div>
        <div class="flex gap-x-2">
            <button @click="setLibraryIndex(null)" class="border border-primary-four rounded-sm px-2 py-1 fs-14 mb-8">Cancel</button>
            <button v-if="selectedIds.length" @click="importTemplate(selectedIds)" class="border border-primary-four rounded-sm px-2 py-1 fs-14 mb-8">Use Now</button>
        </div>
        <div class="flex flex-wrap gap-x-10 gap-y-7 ">
            <!-- <div 
                @click="handleIdSelect(item.id, i)"
                class="relative template bg-no-repeat bg-cover bg-center flex pointer-events-nonerelative  bg-secondary-five rounded p-10 cursor-pointer"
                :class="[find(selectedIds, ['communicator_module_template_id', item.id]) ? 'border-4 border-primary-four' : '']"
                v-for="(item, i) in templates" 
                :key="item.id" style="min-width: 507px; height: 286px"
                :style="{ backgroundImage: `url(${getBgImage(item)})` }"
            >
                <div style="bottom: 100px" class="absolute">
                    <h3 class="text-white fs-26 fw-700">{{ item.title }}</h3>
                </div>
            </div> -->
            <!-- :class="{'border border-primary-one' : template.active }" -->

            <div 
                v-for="(item, index) in templates" 
                :key="index"
                style="width: 507px; height: 286px"
                @click="handleIdSelect(item.id, index)"
                :class="[find(selectedIds, ['communicator_module_template_id', item.id]) ? 'border-4 border-primary-four' : '']"
                class="relative template bg-no-repeat bg-cover bg-center flex pointer-events-nonerelative  bg-secondary-five rounded cursor-pointer"
            >
                <div
                    class="rounded minimum-shadow relative w-full my-com-template-heigh"
                    v-if="item.communicator_module_template"
                >
                    <div
                        class="absolute h-full w-full inset-0"
                        :style="{opacity: item.communicator_module_template.settings.background_color_opacity+'%', backgroundColor: item.communicator_module_template.settings.background_color}"
                    >
                    </div>
                    <img
                        v-if="item.communicator_module_template.image && item.communicator_module_template.image.file_path"
                        class="absolute h-full inset-0 object-cover w-full"
                        :src="item.communicator_module_template.image.file_path"
                        :style="{opacity: item.communicator_module_template.settings.background_image_opacity+'%'}"
                        alt="inst-360"
                    >
                    <div class="my-communicator-template" style="pointer-events:none">
                        <component
                            current-mode="normal"
                            :module="activeModule"
                            :activeTemplate="item.communicator_module_template"
                            :is="getTemplate(item.communicator_module_template.template.component)"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { findIndex, find } from 'lodash'
import { get } from 'lodash'
import { useTemplate } from '@/views/screens/communicator/useTemplate';

const activeModule={}
const selectedIds = ref([])
const defaultImage = '/images/association-bg-dark.svg'

const { getTemplate } = useTemplate();

const handleIdSelect = (id) => {
    const ids = selectedIds.value
    find(ids, ['communicator_module_template_id', id])
    ? ids.splice(findIndex(ids, ['communicator_module_template_id', id]), 1)
    : ids.push({communicator_module_template_id: id})
}

const props = defineProps({
    templates: Array,
    setLibraryIndex: Function,
    importTemplate: Function
})

const getBgImage = (template) => {
    return get(template, 'template.template.img', defaultImage)
}
</script>


<style scoped>

.template:hover>.template-action {
    display: flex;
}
.my-communicator-template{
    height: 706px; 
    width: 1256px; 
    transform-origin: 0 0;
    transform: scale(.403);
}
.my-com-template-height{
    height: 154px;
}
</style>