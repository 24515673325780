<template>
    <div class="pl-10 pr-10 my-10">
        <img src="/images/instrat_logo.svg" alt="logo" class="i-insider-logo" />

        <LinkButton />

        <div class="flex mt-9 gap-x-7">
            <div class="relative i-border-1 border-secondary-five rounded" style="width: 449px">
                <input type="text"
                    class="focus:outline-none py-2 pl-10 font-poppins bg-transparent text-secondary-one placeholder-secondary-two w-full"
                    placeholder="Search by category or template name" />
                <div class="flex items-center justify-between absolute inset-y-0 left-3 pointer-events-none">
                    <svg class="w-4 h-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4ZM2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 11.8487 17.3729 13.551 16.3199 14.9056L21.7071 20.2929C22.0976 20.6834 22.0976 21.3166 21.7071 21.7071C21.3166 22.0976 20.6834 22.0976 20.2929 21.7071L14.9056 16.3199C13.551 17.3729 11.8487 18 10 18C5.58172 18 2 14.4183 2 10Z"
                            fill="var(--color-secondary-two)" />
                    </svg>
                </div>
            </div>
            <!-- -->
        
        </div>
        
        <div class="text-center my-11 i-divider relative">
            <div class="bg-secondary-three inline-block relative z-40 px-8">{{ activeCategory ? getActiveCategoryTitle :
                    'Default'
            }}</div>
        </div>

        <div class="flex gap-7">
            <div class="flex-shrink-0 space-y-5">
                <button class="block h-10 rounded border fs-14 font-medium hover:opacity-90"
                    :class="{ 'bg-primary-four text-white border-white': !activeCategory, 'bg-primary-three text-primary-one border-primary-four': activeCategory }"
                    style="width: 210px" @click="setActiveCategory()">Default</button>
                <div class="block h-10 cursor-pointer rounded border fs-14 font-medium hover:opacity-90 flex justify-center items-center"
                    v-for="category in categories" :key="category.id" @click="setActiveCategory(category)"
                    :class="{
                        'bg-primary-four text-white border-white': activeCategory && category.id === activeCategory,
                        'bg-primary-three text-primary-one border-primary-four': !activeCategory || category.id !== activeCategory
                    }" style="width: 210px">
                    <input type="text" v-model="category.title" class="bg-transparent text-center"
                        @input="updateCategory(category)" />
                </div>
                <button
                    class="block h-10 rounded border border-primary-four bg-white text-secondary-one fs-14 font-medium hover:bg-secondary-three"
                    @click="createCategory()" style="width: 210px"> + </button>
            </div>
            <component 
                :library="library"
                :templates="templates"
                :importTemplate="importTemplate"
                :is="selectedComponent"
                :setLibraryIndex="setLibraryIndex"
            />
        </div>
    </div>
</template>
  
<script setup>
import { ref, onMounted, computed } from 'vue';
import { map } from 'lodash'
import store from "@/store";
import router from '@/routes/routes.js';
import LibraryList from '@/components/communicator/v2/library/LibraryList'
import TemplateList from '@/components/communicator/v2/library/TemplateList'
import LinkButton from '@/components/communicator/v2/library/LinkButton'

const route = router.currentRoute;

const components = {LibraryList, TemplateList}

const selectedLibraryIndex = ref(null)

const selectedComponent = computed(() => {
    return selectedLibraryIndex.value != null ? components.TemplateList : components.LibraryList
})

const templates = computed(() => {
    return library.value[selectedLibraryIndex.value]?.templates
})


const library = ref([])

const fetchLibrary = async () => {
    try {
        const { data: { data, status } } = await store.dispatch('communicator/fetchLibrary')
        if (status) {
            library.value = data
        }
    } catch (err) {
        //
    }
}

const importTemplate = async (templates) => {
    const payload = {
        communicator_id: Number(route.query.communicator_id),
        communicator_layout_id:  Number(route.query.layout_id),
        template_ids: map(templates, 'communicator_module_template_id')
    }
    try{
        const { data: { data, status } } = await store.dispatch('communicator/importTemplate', payload)
        if (status) {
            router.push({name: 'communicator', params: {...route.params, communicator_id: route.query.communicator_id}, query: route.query})
        }
    } catch (err) {
        //
    }
}

const setLibraryIndex = (index) => {
    selectedLibraryIndex.value = index
}


const activeCategory = ref(null);

const categories = computed(() => {
    return store.getters['communicator/categories']
})

const getActiveCategoryTitle = computed(() => {
    let category = categories.value.find(item => item.id == activeCategory.value);
    if (category) {
        return category.title;
    }
    return "Not found";
})


function createCategory() {
    store.dispatch("communicator/createCategory");
}

function updateCategory(category) {
    store.dispatch("communicator/updateCategory", category);
}

function getCategories() {
    store.dispatch("communicator/getCategories");
}

function setActiveCategory(category = null) {
    activeCategory.value = category && category.id ? category.id : null;
}

onMounted(() => {
    fetchLibrary()
    getCategories()
})
</script>
  
<style scoped>
.i-divider::before {
    content: '';
    position: absolute;
    top: 12px;
    left: 0;
    width: 50%;
    height: 1px;
    background: var(--color-secondary-five);
}
.i-divider::after {
    content: '';
    position: absolute;
    top: 12px;
    right: 0;
    width: 50%;
    height: 1px;
    background: var(--color-secondary-five);
}
</style>
  